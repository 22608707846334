// Used for misc UI stuff when editing a product

import { Controller } from "stimulus"
import Trix from 'trix'; // Import the Trix library

export default class extends Controller {

  static targets = ['purchaseButton'];

  static values = {
  }

  connect() {
    const trixEditors = this.element.querySelectorAll('.display-link-trix-tool trix-editor');
    trixEditors.forEach(editor => editor.toolbarElement.innerHTML = Trix.config.toolbar.getLinkToolHTML());
  }

  open_purchase_flow_settings() {
    var purchase_flow_settings = document.getElementById("product-purchase-flow");
    var toggle_panel = purchase_flow_settings.querySelector("div[data-action='click->toggle-settings-panel#toggle']")
    toggle_panel.click();
    purchase_flow_settings.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  RevealPurchaseButton(){
    var agreeToTermsCheckbox = this.element.querySelector("#purchase-agree-to-terms")
    this.purchaseButtonTarget.disabled = !agreeToTermsCheckbox.checked
    this.purchaseButtonTarget.classList.toggle('btn-primary', agreeToTermsCheckbox.checked)
    this.purchaseButtonTarget.classList.toggle('btn-secondary', !agreeToTermsCheckbox.checked)
  }

}