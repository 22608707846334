import {Controller} from "stimulus";

export default class extends Controller {
    static targets = [
        "modal",
        "modalBox",
        "titleContainer",
        "title",
        "titleForm",
        "taskDescription",
        "taskDescriptionForm",
    ];

    connect() {
        this.open()
        this.taskDescriptionTargets.forEach((element) => {
            element.querySelectorAll('a').forEach(function (link) {
                link.target = "_blank"
            })
        })
    }

    open(event) {
        var modal = this.modalTarget;
        modal.classList.remove("hidden");
        modal.classList.add("shown");

        var focus_title_field = this.titleContainer;
        if (focus_title_field) {
            this.showTitleForm();
        }
    }

    close() {
        this.modalTarget.classList.add("hidden");
    }

    // Title form field

    showTitleForm() {
        this.titleTarget.classList.add("hidden", "md:hidden");
        this.titleFormTarget.classList.remove("hidden", "md:hidden");
        this.titleFormTarget.querySelector("input[type=text]").focus();
    }

    hideTitleForm(event) {
        if (event.key == "Tab" || this.titleContainerTarget.contains(event.target) === false) {
            this.titleTarget.classList.remove("hidden", "md:hidden");
            this.titleFormTarget.classList.add("hidden", "md:hidden");
            this.titleContainerTarget.querySelector(".autosave-icon-container").classList.add("hidden");
            this.titleContainerTarget.querySelector(".autosave-icon-container .error-icon").classList.add("hidden");
        }
    }

    displayEditDescription(event) {
        event.preventDefault();
        const taskDescription = event.target.closest('.edit-task-description-link')
        taskDescription.classList.add("hidden");
        this.taskDescriptionTarget.classList.add("hidden");
        this.taskDescriptionFormTarget.classList.remove("hidden");
        this.taskDescriptionFormTarget.querySelector(".form-control").focus();
    }
}
