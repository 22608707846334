import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["newTaskButton"];
  static values = {
    formType: String,
    fromEdit: Boolean
  };

  connect() {
    document.addEventListener("DOMContentLoaded", function () {
      var forms = document.querySelectorAll(
        this.formTypeValue === "task_template" ? ".task-template-form" : ".task-form"
      );
      forms.forEach((form) => {
        const titleField = form.querySelector(".task-title-field");
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              titleField.focus();
              observer.disconnect();
            }
          });
        });

        observer.observe(titleField);
      });
    });
  }

  submitTaskOrTaskTemplate(event) {
    event.preventDefault();
    var form = event.target.closest(this.formTypeValue === "task_template" ? ".task-template-form" : ".task-form");
    var uniqueId = this.generateRandomCode();
    this.createTaskOrTaskTemplate(form, uniqueId);
  }

  createTaskOrTaskTemplate(form, uniqueId) {
    var errorContainer = form.querySelector(".error-explanation");
    var titleElement = document.createElement("div");
    titleElement.classList.add("task-container", `task-container-${uniqueId}`);
    titleElement.setAttribute("data-action", "click->tasks--task-creation#revealTaskOrTaskTemplateForm");
    titleElement.setAttribute("data-task-id", `${uniqueId}`);

    var title = form.querySelector(".task-title-field").value;
    form.id = `task-${uniqueId}`;
    var task_description = form.querySelector(".task-description-field").value;
    if (title) {
      errorContainer.innerHTML = "";

      const renderTasksPartial = (title, task_description) => {
        const tasksPartial = document.createElement("div");
        tasksPartial.classList.add("flex", "items-start", "space-x-4", "py-6");

        const checkboxDiv = document.createElement("div");
        checkboxDiv.classList.add("relative", "top-3px");

        const checkbox = document.createElement("i");
        checkbox.classList.add("fa-regular", "text-4xl", "fa-square", "text-gray-400");
        checkboxDiv.appendChild(checkbox);

        const taskContentDiv = document.createElement("div");
        taskContentDiv.classList.add("flex-1", "space-y-1");
        const taskTitle = document.createElement("div");

        taskTitle.classList.add(
          "text-xl",
          "font-medium",
          `task-title-${uniqueId}`,
          "relative",
          "top-2px",
          "lg:top-4px"
        );
        taskTitle.textContent = title;

        const taskDescription = document.createElement("div");
        taskDescription.classList.add("text-base", "text-gray-500");
        if (task_description) {
          taskDescription.innerHTML = truncateText(task_description);
        }

        const unsavedChanges = document.createElement("div");
        unsavedChanges.classList.add(
          "italic",
          "text-sm",
          "bg-secondary-200",
          "px-2",
          "py-1",
          "rounded-md",
          "text-secondary-600",
          "font-medium",
          "inline-block"
        );
        unsavedChanges.innerHTML =  this.formTypeValue === "task_template" ? "Update message template to save this task" : "Update message to save this task";

        var removeButton = document.createElement("div");
        removeButton.classList.add("task-remove-button", "flex", "items-end", "space-x-4", "relative", "top-2px");
        removeButton.setAttribute("data-action", "click->tasks--task-creation#removeTaskOrTaskTemplate");
        removeButton.setAttribute("data-task-id", `${uniqueId}`);
        removeButton.innerHTML =
          '<i class="fa-solid fa-xmark text-gray-300 cursor-pointer hover:text-black fa-lg"></i>';

        taskContentDiv.appendChild(taskTitle);
        taskContentDiv.appendChild(taskDescription);
        if (this.fromEditValue === true) taskContentDiv.appendChild(unsavedChanges);

        tasksPartial.appendChild(checkboxDiv);
        tasksPartial.appendChild(taskContentDiv);

        tasksPartial.appendChild(removeButton);

        return tasksPartial;
      };

      function removeDivAndBr(description) {
        return description.replace(/<\/?div>/g, "").replace(/<\/?br>/g, " ");
      }

      function truncateText(text, maxLength = 83) {
        const description = removeDivAndBr(text);
        if (description.length > maxLength) {
          return description.substring(0, maxLength) + "...";
        } else {
          return description;
        }
      }

      titleElement.innerHTML = renderTasksPartial(title, task_description).outerHTML;
      form.classList.add("hidden");
      this.element.querySelector(".tasks-display").insertAdjacentHTML("beforeend", titleElement.outerHTML);
      this.revealNewButton();
    } else {
      errorContainer.innerHTML = "<div class='alert alert-error'>Title can't be blank</div>";
    }
  }

  cloneTaskOrTaskTemplateForm(event) {
    event.preventDefault();
    var originalElement =
      this.formTypeValue === "task_template"
        ? document.querySelector(".task-form-clone .task-template-form")
        : document.querySelector(".task-form-clone .task-form");

    var createTaskForm = this.element.querySelector(".create-task-form");
    var taskForm = document.querySelector(".task-form-clone");
    var cloneIndex = taskForm.dataset.nestedAttributeIndex;
    var messageTaskOrder = taskForm.dataset.messageTaskOrder;

    if (createTaskForm) {
      const clone = originalElement.cloneNode(true);

      clone.classList.remove("hidden");
      clone.querySelectorAll("input, textarea, select").forEach((input) => {
        input.id = input.id.replace(/0/g, `${cloneIndex}`);
        input.name = input.name.replace(/\[0\]/g, `[${cloneIndex}]`);
      });

      var field_name = this.formTypeValue === "task_template" ? "_trix_input_task_template" : "_trix_input_task";

      clone.querySelectorAll("trix-editor").forEach((editor) => {
        editor.id = editor.id.replace(/0/g, `${cloneIndex}`);
        editor.setAttribute("input", `${editor.id}${field_name}`);
      });

      if (messageTaskOrder) {
        var messageTaskOrderInput = clone.querySelector(`#message_tasks_attributes_${cloneIndex}_message_task_order`);
        if (messageTaskOrderInput) {
          messageTaskOrderInput.value = Number(messageTaskOrder) + 1;
        }
        taskForm.dataset.messageTaskOrder = Number(taskForm.dataset.messageTaskOrder) + 1;
      }
      createTaskForm.appendChild(clone);
      clone.querySelector(".task-title-field").focus();
      taskForm.dataset.nestedAttributeIndex = Number(taskForm.dataset.nestedAttributeIndex) + 1;
    }
  }

  revealTaskOrTaskTemplateForm(event) {
    var taskID = event.target.closest(".task-container").dataset.taskId;
    var titleElement = this.element.querySelector(".task-container-" + taskID);
    var taskTitle = this.element.querySelector(".task-title-" + taskID).innerText;
    if (taskTitle) {
      var taskForm = this.element.querySelector("#task-" + taskID);
      taskForm.classList.remove("hidden");
      titleElement.remove();
    }
    this.revealNewButton();
    this.hideAllForms(event);
  }

  removeTaskOrTaskTemplate(event) {
    var removeButton = event.target.closest(".task-remove-button");
    var taskID = removeButton.dataset.taskId;
    var taskTitle = this.element.querySelector(".task-title-" + taskID);
    var titleElement = this.element.querySelector(".task-container-" + taskID);
    if (titleElement) {
      var taskForm = this.element.querySelector("#task-" + taskID);
      taskForm.remove();
      titleElement.remove();
    }
  }

  validateDueDays(event) {
    var dueDays = event.target.value;
    // remove all non-numeric characters if there are any
    if (dueDays) {
      var regex = /^[0-9]+$/;
      if (!regex.test(dueDays)) {
        dueDays = dueDays.replace(/[^0-9]/g, "");
        event.target.value = dueDays;
      }
    }
  }

  assignUser(event) {
    var userEmail = event.target.classList.contains(".contact-result-display")
      ? event.target.dataset.userEmail
      : event.target.closest(".contact-result-display").dataset.userEmail;

    var form = event.target.closest(".task-form") || event.target.closest("#task-index-new-task-form");
    var field = form?.querySelector(".task-assign-user-field");
    if (field) field.value = userEmail;
    this.element.querySelector(".contacts-search-results").remove();
    this.element
      .querySelectorAll('[data-search-objects-target="filteredList"]')
      .forEach((element) => element.classList.add("hidden"));
  }

  revealNewButton(event) {
    setTimeout(() => {
      var forms = Array.from(
        this.element.querySelectorAll(this.formTypeValue === "task_template" ? ".task-template-form" : ".task-form")
      );
      var allFormsAreHidden = forms.every((form) => form.classList.contains("hidden"));
      if (this.hasNewTaskButtonTarget && allFormsAreHidden) this.newTaskButtonTarget.classList.remove("hidden");
      if (this.hasNewTaskButtonTarget && !allFormsAreHidden) this.newTaskButtonTarget.classList.add("hidden");
    }, 0);
  }

  hideAllForms(event) {
    var taskUniqueId =
      event.target.querySelector(".task-container")?.dataset.taskId ||
      event.target.closest(".task-container")?.dataset.taskId;
    var currentForm =
      event.target.closest(".task-template")?.querySelector(".task-template-form") ||
      this.element.querySelector(`#task-${taskUniqueId}`);
    if (!currentForm) return;
    var taskTemplates = Array.from(this.element.querySelectorAll(".task-template"));
    taskTemplates.forEach((taskTemplate) => {
      var form = taskTemplate.querySelector(".task-template-form");
      var toggleable = taskTemplate.querySelector(".task-template-toggleable-element");
      if (form === currentForm) return false;
      form.classList.add("hidden");
      toggleable.classList.remove("hidden");
    });
    var forms = Array.from(
      this.element.querySelectorAll(this.formTypeValue === "task_template" ? ".task-template-form" : ".task-form")
    );
    forms.forEach((form) => {
      if (form === currentForm) return false;
      if (form.classList.contains("hidden")) return false;
      form.classList.add("hidden");
      if (form.id) var uniqueId = form.id.split("task-")[1];
      if (uniqueId) {
        this.createTaskOrTaskTemplate(form, uniqueId);
      } else {
        form.remove();
      }
    });
  }

  generateRandomCode() {
    const crypto = window.crypto;
    if (crypto && crypto.getRandomValues) {
      return crypto.getRandomValues(new Uint32Array(1))[0].toString(36);
    }
    return Date.now().toString(36) + Math.random().toString(36).substring(2, 7);
  }
}
