import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = ["filterToggle"]

    connect() {
        this.openTaskModal() //open task modal when url has task_id params
    }

    openTaskModal() {
        var currentPath = window.location.href
        if (currentPath.includes("?task_id=")) {
            var taskId = currentPath.split("?task_id=")[1]
            if (taskId) {
                var taskElement = this.element.querySelector(".task-" + taskId)
                if (taskElement) {
                    taskElement.querySelector('.edit_task_modal')?.click();
                }
            }
        }
    }

    filterTasks(event) {
        const email = this.filterToggleTarget.dataset.currentUserEmail;
        const currentUrl = window.location.href;
        const params = new URLSearchParams(currentUrl.split('?')[1]);

        this.filterToggleTargets.forEach((target) => {
            const filterType = target.getAttribute('data-task-filter');
            const isChecked = target.querySelector('input').value === 'true';

            if (filterType === 'my_tasks') {
                if (isChecked) {
                    params.set('email', email);
                } else {
                    params.delete('email');
                }
            } else {
                if (isChecked || filterType === 'incomplete') {
                    params.set(filterType, isChecked);
                } else {
                    params.delete(filterType);
                }
            }
        });

        const filteredAppliedUrl = `${currentUrl.split('?')[0]}?${params.toString()}`;
        window.location.href = filteredAppliedUrl;
    }

    clearUserAssignment(event) {
        var clearButton = event.target.closest('.clear-user-assignment')
        document.querySelectorAll('.task-assign-user-field').forEach((field) => field.value = null );
        var user_id = clearButton.dataset.userId;
        document.querySelectorAll('#task_default_user_hidden_field').forEach((field) => field.value = user_id );
    }

    unassignTaskUser(event){
        var userElement = event.target.closest('.default-user-details')
        var userHiddenField = userElement.querySelector('#task_default_user_hidden_field')
        var userEmailField = this.element.querySelectorAll('.task-assign-user-field')
        userEmailField.forEach((field) => field.focus() );
        if (userHiddenField){
            userHiddenField.value = null
        }
    }
}